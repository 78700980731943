@tailwind components;
@tailwind utilities;

[x-cloak] { display: none !important; }

@import url(./fonts.scss);

.card {
	@apply rounded-lg max-w-4xl flex gap-4;
}
.icon {
	@apply w-16 h-16;
}

/* old code from example */
body {
	/* height: 400vh; */
  @apply bg-purple-950;
  color: white;
}
svg {
  max-width: 800px;
  overflow: visible;
  /* margin-top: 60vh; */
}
.ball, .ball01, .step, .text-step, .fatline {
 @apply invisible;
}
.active {
  visibility: visible !important;
  @apply ease-in-out duration-300 z-50;
}
.ball {
  @apply fill-white;
}
.ball01 {
  @apply fill-yellow-500;
}
.line {
  @apply fill-none stroke-purple-700 stroke-[2px];
}
.fatline {
  @apply fill-none stroke-purple-700 stroke-[10px];
}
text {
  fill: white;
  color: white;
  font-size: 15px;
  font-family: 'Rijksoverheid-sans', sans-serif;
  visibility: hidden;
  @apply origin-left;
}
p > a, li > a, a {
  @apply text-yellow-500 cursor-pointer transition-colors ease-in-out duration-300;
}
button .bold, button.bold {
  @apply font-bold font-sans;
}
a text:hover {
  @apply fill-yellow-500 text-yellow-500 underline cursor-pointer transition-colors ease-in-out duration-300;
}
.step:hover {
  @apply ease-in-out duration-300;
}
.step {
  @apply z-30 absolute block sm:max-w-sm md:max-w-md mx-auto rounded-lg px-6 py-2 bg-white 
  ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-yellow-500;
}
.popup {
  @apply bg-white text-neutral-900 px-8 py-6 w-full md:w-1/3 h-full md:h-2/3 border border-neutral-200 shadow-xl bottom-0 left-0 right-0 flex flex-col justify-between
}
// aside > div {
//   @apply px-20;
// }
.text-step {
  @apply z-30 absolute block max-w-sm md:max-w-md mx-auto;
}
.step1 {
  top:385px; 
  @apply left-[25%] md:left-[28%];
}
.step2 {
  top:790px; 
  @apply left-[5%] md:left-[10%];
}
.step3 {
  top:1000px; 
  @apply left-[5%] md:left-[25%];
}
.step4 {
  top:1200px; 
  @apply left-[5%] md:left-[25%];
}
.step5 {
  top:1380px; 
  @apply left-[5%] md:left-[10%];
}
.step6 {
  top:1580px; 
  @apply left-[5%] md:left-[15%];
}
.accordion {
  @apply py-1 bold text-left cursor-pointer;
}
.scroll-container {
  @apply overflow-y-scroll from-transparent via-transparent to-neutral-100 bg-gradient-to-b px-2 rounded-sm border border-neutral-100; 
}
tr {
  @apply border-b border-neutral-100 py-2;
}
td {
  @apply align-top;
}