@tailwind base;

@layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl font-bold;
    }
    h3 {
      @apply text-lg;
    }
    a {
      @apply text-yellow-500;
    }
    p, button, td, li {
        @apply text-base;
    }
    li {
        @apply list-item list-disc pl-4;
    }
    p, li {
        @apply leading-6 pb-2;
    }
}
  @font-face {
      font-family: 'Rijksoverheid-sans';
      src: url('../assets/fonts/rijksoverheidsansheading-bold-rijksoverheid.woff') format('woff'),
           url('../assets/fonts/rijksoverheidsansheading-bold-rijksoverheid.svg#rijksoverheidsansheadingbold') format('svg');
      font-weight: bold;
      font-style: normal;
  }
  @font-face {
      font-family: 'Rijksoverheid-sans';
      src: url('../assets/fonts/rijksoverheidsanstext-regular-rijksoverheid.woff') format('woff'),
           url('../assets/fonts/rijksoverheidsanstext-regular-rijksoverheid.svg#rijksoverheidsanstextregular') format('svg');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'Rijksoverheid-serif';
      src: url('../assets/fonts/rijksoverheidserif-italic-rijksoverheid.woff') format('woff'),
           url('../assets/fonts/rijksoverheidserif-italic-rijksoverheid.svg#rijksoverheidserifitalic') format('svg');
      font-weight: normal;
      font-style: italic;
  }
  @font-face {
      font-family: 'Rijksoverheid-serif';
      src: url('../assets/fonts/rijksoverheidserif-regular-rijksoverheid.woff') format('woff'),
           url('../assets/fonts/rijksoverheidserif-regular-rijksoverheid.svg#rijksoverheidserifregular') format('svg');
      font-weight: normal;
      font-style: normal;
  }
  
  html, body {
    @apply w-full overflow-x-hidden scroll-smooth bg-purple-900;
    height:300vh;
  }
  h1, h2, h3, h4, h5, h6, button, input, select, textarea {
      @apply font-sans;
  }
  body, p, a, input, select, textarea {
      @apply font-serif;
  }